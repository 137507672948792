$FuturaPT-Demi: 'FuturaPT-Demi', Arial, sans-serif;
.container {
  font-size: 10px;
  text-transform: uppercase;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.02),
    0px -10px 15px rgba(226, 232, 246, 0.4);
  font-family: $FuturaPT-Demi;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  z-index: 1;

  a {
    border-bottom: rgba(253, 253, 253, 0) solid 5px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .itemActive {
    border-bottom: #f67c48 solid 5px;
    opacity: 1;
  }

  .itemImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 5px;

    img {
      margin-bottom: 10px;
    }
  }
}
