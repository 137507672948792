.paymentsContainer {
  margin: -65px 0 -100px;
  width: 100%;
  height: 100vh;
}

.paymentsBody {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 768px;
  }

  .titleWrapper {
    height: 876px;
    padding-top: 52px;
    padding-bottom: 24px;
  }

  .title {
    letter-spacing: 1px;
    color: #fff;
    font-size: 72px;
    line-height: 1.17;
    font-weight: 600;

    @media (max-width: 576px) {
      font-size: 52px;
      line-height: 1;
    }
  }

  .description {
    max-width: 624px;
    padding-bottom: 15px;
    color: #fff;

    label {
      display: flex;
      align-items: flex-start;
      text-align: left;
      margin-bottom: 10px;
      cursor: pointer;
    }

    input {
      flex-shrink: 0;
    }

    a {
      color: #ff8562;
    }

    @media (max-width: 576px) {
      padding: 15px;
    }
  }

  .buttonsWrapper {
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  .button {
    height: 60px;
    margin: 0 6px 10px;
    padding-left: 60px;
    padding-right: 60px;
    font-weight: 600;
    color: #000000;
    background-color: #ffffff;
    border-radius: 30px;
    text-transform: uppercase;
    font-size: 18px;
    border: none;
    cursor: pointer;

    transition: opacity 0.15s linear;

    &:last-child {
      color: #fff;
      background-color: #f85651;
    }

    &:disabled,
    &[disabled] {
      cursor: default;
      opacity: 0.5;
    }

    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }
}

.paymentsFooter {
  padding-top: 60px;
  padding-bottom: 75px;
  background-color: #f17c77;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.footerBody {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
  padding: 0;
}

.footerMenu {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 0 10px;
    color: #ffffff;
    font-size: 22px;
    font-weight: 600;

    @media (max-width: 768px) {
      padding: 5px 0;
    }

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.footerCopyright {
  text-align: center;
  color: #ffffff;
  opacity: 0.7;
  padding-top: 40px;
  padding-bottom: 10px;
}
